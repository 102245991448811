import { Pipe, PipeTransform } from '@angular/core';

/**
 * Number to display in short form
 * Like - Display 764M insted of 764235809
 * @param input - Number value
 * @returns Short form of given number
 */
@Pipe({
  name: 'shortNumber',
  standalone: true
})
export class ShortNumberPipe implements PipeTransform {
  transform(input: number): string | number {
    const suffixes = ['M', 'B', 'T', 'P', 'E'];

    if (input < 1000000) {
      return input;
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000000));

    return `${(input / Math.pow(1000000, exp)).toFixed(0)}${suffixes[exp - 1]}`;
  }
}
